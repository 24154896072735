export default {
  // ? =========================
  // ? =======  Layout  ========
  // ? =========================

  navigation: {
    '.container': {
      backgroundColor: 'white',
      position: 'static',
      borderBottom: '1px solid lightgrey',
      padding: ['0.5rem', '0.5rem', '0.5rem', '0rem'],
      zIndex: '1003'
    },
    '.containerScrolled': {
      backgroundColor: 'white',
      borderBottom: '1px solid lightgrey',
      zIndex: '1003'
    },
    '.smallNavMenu > div': {
      borderColor: 'black'
    },
    '.navItem': {
      color: 'black'
    },
    '.hamburger > div': {
      backgroundColor: 'black'
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      width: '50%'
    },
    '.socialIconsContainer, .phoneContainer': {
      color: 'black',
      borderColor: 'black',
      svg: {
        path: {
          fill: 'black'
        }
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: ['65px', '65px']
      }
    },

    '.navBlockOpen': {
      width: '50%',
      height: '100vh',
      backgroundColor: '#ffffffc9',
      top: '0rem',
      position: 'fixed',
      left: '50%'
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'light',
    color: 'black',
    maxWidth: 'unset',
    justifyContent: 'center',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },
    '.column': {
      maxWidth: '600px',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.sectionHeading': {
      color: 'primary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.gonationLogo': {
      filter: 'unset',
      fontSize: '0.8rem'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black',
      fontWeight: 'bold',
      fontSize: '0.8rem'
    },

    '.quote': {
      color: 'secondary',
      margin: '1rem 0rem',
      fontFamily: 'display',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      display: 'flex',
      maxWidth: '600px',
      justifyContent: 'center'
    },
    '.socialContainer': {
      margin: '1rem 0rem'
    },
    '.bottomBar .multiButtonContainer': {
      border: 'none',
      a: {
        color: 'black',
        ':hover': {
          color: 'white'
        }
      }
    }
  },

  ctaWidget: {
    a: {
      backgroundColor: 'secondary',
      borderRadius: '300px'
    }
  },

  title: {
    order: '1',
    fontSize: ['2.5rem', '', '3.5rem', '4.5rem'],
    color: 'secondary',
    fontFamily: 'Amarante',
    textTransform: 'uppercase',
    textShadow: '2px 2px 0px #e6d654'
  },

  subtitle: {
    color: '#62c4d4',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '2',
    fontFamily: 'body',
    // fontSize: '0.85rem',
    textTransform: 'uppercase'
  },

  text: {
    order: '3',
    color: 'grey',
    lineHeight: '1.8'
  },

  // ? =========================
  // ? ======  Home page  ======
  // ? =========================

  homepageHero: {
    height: ['60vh', '', '', '60vh'],
    marginBottom: ['2rem', '', '2rem', '2rem'],
    '.slick-slider': {
      height: ['60vh', '', '', '60vh'],
      '.slick-slide > div': {
        height: ['60vh', '', '', '60vh']
      },
      '.slick-prev, .slick-next': {
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'primary',
        height: '30px',
        borderRadius: '100px',
        padding: '5px'
      },
      '.slick-slide img': {
        height: ['60vh', '', '', '60vh']
      }
    },
    '.hero_content_container': {
      width: ['75%', '50%', '', '55%', '50%', '45%'],
      marginLeft: ['', '', '', '4rem'],
      top: 'unset',
      left: '0rem',
      bottom: '0rem',
      transform: 'unset',
      '.title': {
        fontSize: ['2.5rem', '', '3.5rem', '4.5rem'],
        order: '2',
        color: 'secondary',
        fontWeight: '500',
        fontFamily: 'Amarante',
        whiteSpace: 'pre-wrap',
        textTransform: 'uppercase',
        position: 'relative',
        left: '1rem',
        textShadow: '2px 2px 0px #e6d654'
      },
      '.subtitle': {
        color: 'dark',
        fontWeight: 'bold',
        textTransform: 'initial',
        order: '1',
        fontFamily: 'body',
        fontSize: ['1rem', '', '1.5rem', '2.5rem'],
        textTransform: 'uppercase',
        textDecoration: 'underline'
      },

      '.text': {
        lineHeight: '1.75',
        marginBottom: '1rem',
        order: '3'
      }
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '40%'],
      maxHeight: ['', '', '55vh']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'cover'
    },
    '.content': {
      backgroundColor: ['', '', '#333333'],
      color: 'text',
      margin: ['', '', '2rem 1rem'],
      width: ['100%', '100%', '60%']
    },
    '.text': {
      lineHeight: '1.5',
      color: 'light',
      fontSize: '2.5rem',
      letterSpacing: '1px',
      fontFamily: 'heading'
    },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      color: 'light',
      letterSpacing: '3px'
    },
    '.date': {
      color: 'light',
      display: 'none'
    },

    '.shoutCTA': {
      variant: 'buttons.primary'
    }
  },

  homepageAbout: {
    marginBottom: '2rem',
    backgroundColor: 'transparent',
    '.lazyload-wrapper': {},
    '.content': {
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      order: ['', '', '', '2'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  homepageMenu: {
    marginBottom: '2rem',
    backgroundColor: 'transparent',
    '.lazyload-wrapper': {},
    '.content': {
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  homepageGallery: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageEvents: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageShop: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageContentText: {
    '::before': {
      backgroundColor: '#c7e9f0b8'
    },
    '.title': {
      marginBottom: '2rem',
      borderTop: '2px solid white',
      borderBottom: '2px solid white',
      padding: '1rem',
      fontFamily: 'display',
      textShadow: '2px 2px 0px #e6d654',
      color: 'secondary',
      order: 1
    },
    '.subtitle': {
      maxWidth: '650px',
      fontSize: '2rem',
      order: 2
    },
    '.text': {
      order: 3,
      color: 'white'
    },
    a: {
      order: 4
    }
  },

  homepageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    }
  },

  homepageInstagram: {
    '.logoAndTextContainer ': {
      // margin: '3rem 0rem 2rem',
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },

  // ? =========================
  // ? =====  About page  ======
  // ? =========================

  aboutSection: {
    backgroundColor: 'transparent',
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? =========================
  // ? ====  Meal prep page  ===
  // ? =========================

  menu: {
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: ['1rem', '', '1.5rem']
    },
    '.menuSectionDescription': {
      fontSize: '1rem',
      maxWidth: '767px',
      margin: ['1rem', '', '1.5rem'],
      lineHeight: '1.7',
      textAlign: 'left'
    },
    '.allInContainerWrapper': {},
    '.menuItemInnerContainer': {},
    '.menuItemImageContainer': {},
    '.menuItemName': {
      variant: 'customVariants.subtitle',
      order: 'unset',
      textAlign: 'left'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {},
    '.cellImageContainer': {},
    '.menuCell, .cellImage': {},
    '.menuCell': {},
    '.cellName': {},
    '.menuItemContainerImgActive': {
      width: ['100%', '100%', '100%', '100%']
    },
    '.backToMenuBtn': {
      variant: 'buttons.primary',
      marginBottom: '3rem'
    }
  },

  menuImage: {
    variant: 'customVariants.homepageAboutSection',
    justifyContent: 'center',
    img: {
      objectFit: 'contain'
    },
    '.content': {
      display: 'none'
    }
  },

  // ? =========================
  // ? ====  Catering page  ====
  // ? =========================

  cateringMenu: {
    variant: 'customVariants.menu'
  },

  // ? =========================
  // ? ====  Gallery page  =====
  // ? =========================

  gallery: {
    '.albumsContainer': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? =========================
  // ? ====  Contact Page  =====
  // ? =========================

  locationMap: {
    h3: {
      textTransform: 'uppercase'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  contactForm: {
    padding: '4rem 1rem',
    backgroundColor: '#fafafa',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary'
      }
    }
  },

  cateringPDFs: {
    p: '10vw 1rem',
    '.section': {
      maxWidth: '1200px'
    },
    '.ctaButton, .secondaryCtaButton': {
      variant: 'buttons.primary',
      width: '250px',
      height: '250px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}
